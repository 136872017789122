<template>
  <div class="channelOne">
    <div class="back" @click="goBack">
      <i class="el-icon-arrow-left"></i> 返回
    </div>
    <div class="topContent">
      <div class="left">
        <img
          src="https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/logo.png"
          alt=""
        />
      </div>
      <div class="right">
        <div class="rightTop">北京朝阳燕京雍阳幼儿园</div>
        <div class="rightBtm">
          Beijing Chaoyang Yanjing Yongyang Kindergarten
        </div>
      </div>
    </div>

    <!-- 新建客户 -->
    <div class="newKehu">
      <div class="content">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item label="姓名" prop="name">
            <br />
            <el-input v-model="form.name" placeholder="请输入姓名" />
          </el-form-item>
          <el-form-item label="年龄" prop="sex">
            <br />
            <el-input
              v-model="form.sex"
              placeholder="请输入年龄"
              @input="(v) => (form.sex = v.replace(/[^\d.]/g, ''))"
              maxlength="3"
            />
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <br />
            <el-input v-model="form.phone" placeholder="请输入联系方式" />
          </el-form-item>

          <el-form-item label="渠道名称" prop="sourceName">
            <br />
            <el-select v-model="form.sourceName" placeholder="请选择渠道名称">
              <el-option
                v-for="item in qudaoList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="queding" @click="submitForm">确 定</div>
        <div style="height: 10px"></div>
      </div>
    </div>
    <!-- 新建成功 -->
    <img
      src="https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/success.png"
      alt=""
      class="successImg"
      v-if="isSuccess"
    />
    <!-- 新建失败 -->
    <img
      src="https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/fail.png"
      alt=""
      class="successImg"
      v-if="isFail"
    />
  </div>
</template>

<script>
// import { listSource } from "@/api/kindergarten/qudao";
// import {
//   addReservation,
//   updateReservation,
// } from "@/api/kindergarten/appointment";
export default {
  name: "channelOne",
  components: {},
  data() {
    return {
      isSuccess: false,
      isFail: false,
      open: true,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "请输入学生姓名！",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请输入学生年龄！",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入联系方式！",
            trigger: "blur",
          },
        ],
        sourceName: [
          {
            required: true,
            message: "请选择渠道名称！",
            trigger: "blur",
          },
        ],
      },
      qudaoList: [],
    };
  },
  mounted() {
    this.isFail = false;
    this.isSuccess = false;
    console.log(this.isSuccess);

    this.api.listSource().then((res) => {
      console.log(res);
      this.qudaoList = res.data.rows;
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    cancel() {
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        name: null,
        phone: null,
        reservationTime: null,
        state: null,
      };
      this.resetForm("form");
    },
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.api.updateReservation(this.form).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            // 当前登录用户名
            this.form.reamrk4 = localStorage.getItem("h5userName");
            this.api.addReservation(this.form).then((response) => {
              console.log(response);
              // if (response.code == 200) {
              this.isSuccess = true;
              setTimeout(() => {
                this.isSuccess = false;
                this.$router.push({ path: "/channelTwo" });
              }, 3000);
              // } else if (response.code == 500) {
              //   this.isFail = true;
              //   console.log(this.isFail);
              // }
            });
          }
        }
      });
    },
  },
};
</script>
<style scoped>
.channelOne {
  width: 100vw;
  height: 100vh;
  background-image: url("https://yanjingyongyangyoueryuan.oss-cn-beijing.aliyuncs.com/bg.png");
  background-size: 100% 100%;
}
.back {
  width: 80px;
  height: 60px;
  font-size: 15px;
  color: white;
  padding-top: 20px;
  padding-left: 20px;
}

.topContent {
  width: 335px;
  margin: 0 auto;
  padding-top: 60px;
  display: flex;
  justify-content: space-around;
}
.left {
  width: 40px;
  height: 40px;
}
.left img {
  width: 100%;
  height: 100%;
}
.right {
  width: 330px;
  margin-left: 10px;
}
.rightTop {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 16px;
}
.rightBtm {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}

.content {
  width: 345px;
  margin: 0 auto;
  margin-top: 30px;
  background: white;
  padding-top: 30px 16px;
  border-radius: 9px;
}

div /deep/.el-form {
  width: 343px;
  margin: 0 auto;
}
.el-input--medium,
div /deep/.el-input__inner,
.el-input {
  width: 343px;
  height: 50px !important;
  background: #ffffff;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  opacity: 1;
}

div /deep/.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.queding {
  width: 311px;
  height: 50px;
  background: #03549e;
  border-radius: 100px 100px 100px 100px;
  opacity: 1;
  font-size: 18px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 50px;
  text-align: center;
  margin: 20px auto;
}

.el-form-item {
  margin-bottom: 5px;
}

.successImg {
  width: 136px;
  height: 130px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
</style>
